export const SYMSPACE = "SyMSpace"
export const PROJECT_NAME = "SyMSpace Choice"
export const PROJECT_DESCRIPTION = "SyMSpace Choice is an interactive visualization \n built to explore SyMSpace Optimizer results."
export const PROJECT_LEAD = "Lena Cibulski"
export const PROJECT_LEAD_URL = "https://www.igd.fraunhofer.de/en/institute/about-us/staff/lena-cibulski"

// Data
export type SingleTableData = {
    table: Table
}

export type Table = {
    "name": string,
    "columns": Array<Attribute>,
    "rows": Array<Row>
}

export type Attribute = {
    // from file
    "name" : string,            // unique identifier
    "description" ?: string,
    "unit" ?: string,
    "obj" ?: OPTIMIZATION_TYPE,
    // computed
    "min" : number,
    "max" : number,
    "attr_type" : ATTRIBUTE_TYPE,
    "color" ?: string,
    "categories" ?: Array<string>
}

export type Row = {
    "ID" : number,                       // unique identifier (in case of topologies-combined, it is made up of the first character representing the topology and the remaining characters the actual values ID)
    [key: string]: number | string       // keys are all column names in the table, categorical values are string
};

export enum OPTIMIZATION_TYPE {
    MIN,
    MAX,
    NONE
}

export enum ATTRIBUTE_TYPE {
    NOMINAL,
    NUMERICAL
}

export enum COLOR_CODING {
    DEFAULT,
    GRADIENT_BRUSH,
    CATEGORIES
}

// Taken from https://ich.unesco.org/en/dive&display=threat#tabs
export const COLOR_MAP = ["#9850A2", "#EB474F", "#F0B807", "#2074A0", "#7EB852"];