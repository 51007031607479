import React, { createContext, useContext, useReducer } from 'react';
import { Action, Props, SessionContextState } from './types';

const State = createContext<SessionContextState | undefined>(undefined);
const Dispatch = createContext<any>(undefined);

const reducer = (state: SessionContextState, action: Action) => {
    switch(action.type) {
        case "set-data":
            return {
                ...state,
                data: action.payload
            };
        /*case "set-brushed-rows":
            return {
                ...state,
                brushedRows: action.payload
            };*/
        case "set-flagged-rows":
            return {
                ...state,
                flaggedRows: action.payload
            };
        default:
            throw new Error("SessionContext: could not handle action type:" + action.type);
    }
}

export const Provider = ({ children }: Props) => {
    const initialState = {
        data: {name: "", columns: [], rows: []},
        brushedRows: [],
        flaggedRows: []
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <State.Provider value={state}>
            <Dispatch.Provider value={dispatch}>
                {children}
            </Dispatch.Provider>
        </State.Provider>
    )
}

export const useStateContext = () => {
    let context = useContext(State);
    if (context === undefined)
        throw Error("SessionContext is undefined. Did you place the consumer outside of the context?");
    return context;
};

export const useDispatchContext = () => {
    let context = useContext(Dispatch);
    if (context === undefined)
        throw Error("SessionContext is undefined. Did you place the consumer outside of the context?");
    return context;
}
