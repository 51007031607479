import React from 'react';

import {Route} from 'react-router-dom';
import {GDPR, Imprint} from "@iva/page-templates-react";

import { PROJECT_NAME } from './config/constants';
import {Routes} from './config/routes';
import Header from './layouts/Header';
import LandingPage from "./pages/LandingPage/LandingPage";
import {Provider as SessionProvider} from "./contexts/SessionContext";
import SyMSpaceChoice from "./pages/SyMSpaceChoice/SyMSpaceChoice";

import "bootstrap/dist/css/bootstrap.min.css" // Because Reactstrap does not include Bootstrap CSS
import './App.css';

// Set up fontawesome icons
import {library} from '@fortawesome/fontawesome-svg-core';
import {faBars, faTable, faTh} from '@fortawesome/free-solid-svg-icons';
library.add(faBars, faTable, faTh);

const App : React.FunctionComponent<{}> = () => {
    return (
        <SessionProvider>
            <div id="app">
                <Header />
                <main className="page-content">
                    <Route exact path={Routes.root} component={() => <LandingPage/>} />
                    <Route path={Routes.demo + "/:filename?/:port?"} component={() => <SyMSpaceChoice/>} />
                    <Route path={Routes.dsgvo} component={() => <GDPR german={false}
                                                                      projectName={PROJECT_NAME}
                                                                      projectUrl={"<project_url>"}
                                                                      contactFormEnabled={false}
                                                                      transfer={false}
                                                                      webanalytics={{googleanalytics: false, wireminds: false, matomo: true}}
                                                                      youtube={false}
                                                                      social={{facebook: false, pinterest: false, twitter: false, googleplus: false, xing: false}}/>} />
                    <Route path={Routes.imprint} component={() => <Imprint/>} />
                </main>
            </div>
        </SessionProvider>
    );
};

export default App;
