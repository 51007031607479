import * as React from 'react';
import {Nav, Navbar, NavbarBrand, NavbarText} from 'reactstrap';
import {Routes} from "../config/routes";
import "./Header.css";
import {PROJECT_NAME} from "../config/constants";
import LCMLogo from "../assets/img/lcm-logo-desktop-weiß.png";

const Header : React.FunctionComponent<{}> = () => {
    return (
        <div id="header">
            <Navbar dark expand="xl" fixed="top">
                <NavbarBrand href={Routes.root}>{PROJECT_NAME}</NavbarBrand>
                <Nav className="ml-auto mr-auto" navbar>
                </Nav>
                <NavbarText>A cooperation with </NavbarText>
                <a href="https://www.lcm.at" target="_blank" rel="noreferrer"><img src={LCMLogo} alt="Linz Center of Mechatronics GmbH" height={30}></img></a>
            </Navbar>
        </div>
    );
};

export default Header;
