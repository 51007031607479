import * as React from "react";
import {ScatterPlotMatrix} from "@iva/parallel-coordinates";
import {
    useStateContext as useStateSessionContext
} from "../../contexts/SessionContext";
import Utils from "../../config/Utils";

const ScatterPlotContainer : React.FunctionComponent<{}> = () => {

    const store = useStateSessionContext();

    return(
        <ScatterPlotMatrix  rows={store.data.rows}
                            columns={store.data.columns.filter(attribute => Utils.hasTypeObjective(attribute))}
                            fullMatrix={false}
                            previousFullScreenCell={null}
                            onPlotClick={() => {}}
                            flaggedIDs={store.flaggedRows}/>
    );
};

export default ScatterPlotContainer;