import * as React from 'react';

import "./Footer.css";
import {PROJECT_LEAD, PROJECT_LEAD_URL, PROJECT_NAME} from "../config/constants";

const Footer : React.FunctionComponent<{}> = () => (
    <div id="footer">
        <div className="footer-items">
            <div className="footer-item">
                <span>{PROJECT_NAME} is a project maintained by <a href="https://www.igd.fraunhofer.de/kompetenzen/technologien/visual-analytics">Fraunhofer IGD</a> under the lead of <a href={PROJECT_LEAD_URL}>{PROJECT_LEAD}</a></span>
            </div>
            <div className="footer-item">
                <a href='/imprint'>Imprint</a>
            </div>
            <div className="footer-item">
                <a href='/dsgvo'>GDPR</a>
            </div>
        </div>
    </div>
);

export default Footer;
