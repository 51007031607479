import * as React from "react";
import {CarouselConfiguration, Carousel, SwipeableDrawer} from "@iva/page-templates-react";
import ParallelCoordinatesContainer from "./ParallelCoordinatesContainer";
import TableView from "../../components/TableView/TableView";
import {Attribute} from "../../config/constants";
import {useStateContext as useStateSessionContext} from "../../contexts/SessionContext";
import ScatterPlotContainer from "./ScatterPlotContainer";

const config: CarouselConfiguration = {
    slides: [
        {view: <ParallelCoordinatesContainer/>, icon: ['fas', 'bars']},
        {view: <ScatterPlotContainer/>, icon: ['fas', 'th']}],
    activeIndex: 0
};

const SyMSpaceChoice : React.FunctionComponent<{}> = () => {
    const store = useStateSessionContext();

    return(
        <div style={{width: "100%", height: "100%", position: "relative"}}>
            <Carousel slides={config.slides} activeIndex={config.activeIndex}/>
            <SwipeableDrawer icon={['fas', 'table']} description={store.flaggedRows.length + "/" + store.data.rows.length + " (flagged/total)"}>
                <TableView id={"data-table"}
                           rows={store.data.rows}
                           columns={store.data.columns.map((attribute: Attribute) => {return {"ref": attribute.name};})}/>
            </SwipeableDrawer>
        </div>
    );
};

export default SyMSpaceChoice;
