import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {Alert} from "reactstrap";

import DropDownSelector from "../../components/DropDownSelector";
import {Attribute, COLOR_CODING, OPTIMIZATION_TYPE, Table} from "../../config/constants";
import Utils from "../../config/Utils";

import {Content, Sidebar, SidebarItem, SidebarLayout} from "@iva/page-templates-react";
import {ColorCodingHelper, MultiCriteriaParallelCoordinates} from "@iva/parallel-coordinates";

import defaultData from "../../assets/data/M17010-5O.json";
import {
    useDispatchContext as useDispatchSessionContext,
    useStateContext as useStateSessionContext
} from "../../contexts/SessionContext";

// The selection managed within the MultiCriteriaParallelCoordinates is the single source of truth. This means that...
// ... if no flaggedIDs are given as props, MultiCriteriaParallelCoordinates is an uncontrolled component
// ... props flaggedIDs only change upon selection update via interaction in SyMSpace optimizer
// ... notification callback onFlaggedIDsChange only fires upon selection update via interaction in MultiCriteriaParallelCoordinates

type State = {
    currentVersion: string,
    error: string | null,
    selectedObjectives: Array<string>,
    violinPlotsEnabled: boolean,
    curveSmoothingEnabled: boolean,
    colorCoding: ColorCodingHelper
};

const paddingLeftSubsection = "15px";

const ParallelCoordinatesContainer : React.FunctionComponent<{}> = () => {
    const [state, setState] = useState<State>({
        currentVersion: "",
        error: null,
        selectedObjectives: [],
        violinPlotsEnabled: false,
        curveSmoothingEnabled: false,
        colorCoding: new ColorCodingHelper().setType(COLOR_CODING.GRADIENT_BRUSH)
    });

    // Only set up long polling once the initial version has been received from SyMSpace
    const subscriptionInitialized = useRef(false);

    const store = useStateSessionContext();
    const dispatch = useDispatchSessionContext();

    /*** Set up data source, either fetched from SyMSpace or demo data
     *   This is only executed on first render of the visualization
    ***/
    const { filename, port } = useParams<{filename?: string, port?: string}>();
    //
    useEffect(() => {
        // Fetch initial optimization version in SyMSpace, version change triggers long polling setup and initial data fetch
        if (filename !== undefined && port !== undefined) {
            (async () => {
                const v = await fetchVersion();
                setState(prevState => ({
                    ...prevState,
                    currentVersion: v
                }));
            })();
        }
        // Store demo data in component state
        else {
            let data: Table = Utils.deserializeJSON(defaultData).table;
            dispatch({"type": "set-data", "payload": data});
            setState(prevState => ({
                ...prevState,
                selectedObjectives: data.columns.filter(attribute => Utils.hasTypeObjective(attribute)).map(objective => objective.name)
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(state.currentVersion !== "" && filename !== undefined && port !== undefined) {
            // Set up long polling for asynchronous updates like selection change
            if (!subscriptionInitialized.current) {
                subscribeSyMSpace();
                subscriptionInitialized.current = true;
            }
            // Fetch optimization results and selection from SyMSpace whenever the version has changed
            fetchData();
        }
        // eslint-disable-next-line
    }, [state.currentVersion]);

    return(
        <React.Fragment>
            <SidebarLayout>
                <Sidebar>
                    <SidebarItem title={"Data"}>
                        {renderAttributeCheckboxes()}
                    </SidebarItem>
                    <SidebarItem title={"Visualization"}>
                        {renderSmoothingCheckbox()}
                        {renderViolinPlotsCheckbox()}
                        {renderColorCodingControl()}
                    </SidebarItem>
                </Sidebar>
                <Content>
                    {
                        state.error !== null &&
                        <Alert color="danger" toggle={() => setState(prevState => ({...prevState, error: null}))}>
                            {state.error}
                        </Alert>
                    }
                    <MultiCriteriaParallelCoordinates rows={store.data.rows}
                                                      dimensions={store.data.columns.filter(attribute => state.selectedObjectives.includes(attribute.name))}
                                                      lineBrushEnabled={false}
                                                      violinPlotsEnabled={state.violinPlotsEnabled}
                                                      curveSmoothingEnabled={state.curveSmoothingEnabled}
                                                      colorCodingHelper={state.colorCoding}
                                                      selectedCategories={[]}
                                                      flaggedIDs={store.flaggedRows}
                                                      onFlaggedIDsChange={(ids: Array<number>) => flaggedIDsChangeHandler(ids)}/>
                </Content>
            </SidebarLayout>
        </React.Fragment>
    );

    /* FETCHING START */

    async function fetchData() {
        // Use current version to fetch optimization results
        const json = await fetchResults(state.currentVersion);
        /* Store fetched data in component state */
        let data: Table = Utils.deserializeJSON(json).table;
        dispatch({"type": "set-data", "payload": data});

        setState(prevState => ({
            ...prevState,
            selectedObjectives: data.columns.filter(attribute => Utils.hasTypeObjective(attribute)).map(objective => objective.name)
        }));

        // Use current version to fetch initial selection
        const selection = await fetchSelection(state.currentVersion);
        dispatch({"type": "set-flagged-rows", "payload": selection});
    }

    function fetchVersion() {
        return fetch("http://localhost:" + port + "/symspace/optimizer/plot/version?name=" + filename, {method: "GET"})
            .then(function (response) {
                if (response.ok) {
                    return response.text();
                } else {
                    setState(prevState => ({...prevState, error: 'Connection Error ' + response.status + '(' + response.statusText + ') at ' + response.url}));
                    return "";
                }
            })
            .catch(error => {
                setState(prevState => ({...prevState, error: error.name + ': ' + error.message}));
                return "";
            });
    }

    function fetchResults(v: string | void) {
        return fetch("http://localhost:" + port + "/symspace/optimizer/plot/values?name=" + filename + "&version=" + v, {method: 'GET'})
            .then(function (response) {
                if (response.ok) {
                    return response.json();
                } else
                    setState(prevState => ({...prevState, error: 'Connection Error ' + response.status + '(' + response.statusText + ') at ' + response.url}));
            })
            .catch(error => {
                setState(prevState => ({...prevState, error: error.name + ': ' + error.message}));
            });
    }

    function fetchSelection(v: string | void) {
        return fetch("http://localhost:" + port + "/symspace/optimizer/plot/selection?name=" + filename + "&version=" + v, {method: 'GET'})
            .then(function (response) {
                if (response.ok) {
                    return response.json();
                } else
                    setState(prevState => ({...prevState, error: 'Connection Error ' + response.status + '(' + response.statusText + ') at ' + response.url}));
            })
            .catch(error => {
                setState(prevState => ({...prevState, error: error.name + ': ' + error.message}));
            });
    }

    /*** Set up long polling connection to SyMSpace for selection retrieval
     *   SyMSpace Choice sends a request to SyMSpace
     *   SyMSpace doesn't close the connection until it has an update to send (e.g. the selection has changed)
     *   When an update appears, SyMSpace responds to the request with the respective payload (e.g. the selected individuals)
     *   SyMSpace Choice processes the response and sends out a new request immediately
    ***/
    // How to start and stop interval polling: https://github.com/vivek12345/react-polling-hook/blob/master/src/usePolling.js
    async function subscribeSyMSpace() {
        let response = await fetch("http://localhost:" + port + "/symspace/optimizer/plot/event?name=" + filename, {method: "GET"});

        // Connection has timed out, may have been pending for too long and SyMSpace closed it - let's reconnect
        if (response.status === 502) {
            await subscribeSyMSpace();
        }
        // Something else has gone wrong - let's show the response text
        else if (!response.ok) {
            setState(prevState => ({...prevState, error: response.statusText}));
            // Reconnect in one second
            await new Promise(resolve => setTimeout(resolve, 1000));
            await subscribeSyMSpace();
        }
        // Either optimization version or selection have changed
        else {
            /*TODO: Add basic scatter plots */
            /*TODO: Line brush: suppress hovering as long as line brush is in progress, alternatively remove (low priority) */
            /*TODO: abgeschnittenes ID-Label (low priority) */
            const v = await fetchVersion();
            // Version did not change -> update was triggered by selection change
            if(v === state.currentVersion) {
                const selection = await fetchSelection(state.currentVersion);
                // Make the selection available to all consumers within this application
                dispatch({"type": "set-flagged-rows", "payload": selection});
            }
            // Update was triggered by optimization change -> state update automatically triggers data fetch
            else {
                setState(prevState => ({
                    ...prevState,
                    currentVersion: v
                }));
            }
            // Call subscribe() again to get the next update
            await subscribeSyMSpace();
        }
    }

    /* FETCHING END */

    /* ATTRIBUTE CHECKBOXES START */

    function renderAttributeCheckboxes() {
        let objectives = store.data.columns.filter(attribute => Utils.hasTypeObjective(attribute));
        let inputAttributes = store.data.columns.filter(attribute => attribute.name !== "ID" && !Utils.hasTypeObjective(attribute));
        const style = inputAttributes.length > 0 ? {display: "block"} : {display: "none"};;

        return(
            <div>
                {objectives.map((objective: Attribute, i: number) =>
                    <div key={i} id="attributes-container">
                        <input type="checkbox"
                               onChange={() => toggleAttribute(objective)}
                               checked={state.selectedObjectives.includes(objective.name)}/>
                        {objective.name}
                    </div>
                )}
                <div style={style}>
                    <label className="switch">
                        <input type="checkbox"
                               onChange={event => allInputAttributesHandler(event.target.checked)}/>
                        <span className="slider round"/>
                        <span className="label">Input Attributes</span>
                    </label>
                </div>
                <div id="input-attributes"
                     style={{"paddingLeft" : paddingLeftSubsection, "display" : "none"}}>
                    {inputAttributes.map((attribute: Attribute, i: number) =>
                        <div key={i}>
                            <input type="checkbox"
                                   onChange={() => toggleAttribute(attribute)}
                                   checked={state.selectedObjectives.includes(attribute.name)}/>
                            {attribute.name}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function toggleAttribute(objective: Attribute) {
        let idx = state.selectedObjectives.indexOf(objective.name);
        let selected = state.selectedObjectives.slice();

        // Is currently selected => deselect
        if(idx > -1)
            selected.splice(idx, 1);
        // Is newly selected
        else
            selected.push(objective.name);

        setState(prevState => ({...prevState, selectedObjectives: selected}));
    }

    function allInputAttributesHandler(enable: boolean) {
        let selected = state.selectedObjectives.slice();

        let inputCheckboxes = document.getElementById("input-attributes")!;

        // Remove all input attributes from list of selected and hide checkboxes
        if(!enable) {
            selected = selected.filter((attributeName: string) => {
                let attribute = store.data.columns.find((attribute: Attribute) => attribute.name === attributeName)!;
                return attribute.obj !== undefined;
            });
            inputCheckboxes.style.display = "none";
        }
        // Add all input attributes to list of selected if not there already and show checkboxes
        else {
            let inputAttributes = store.data.columns.filter(attribute => attribute.obj === undefined);
            inputAttributes.forEach((attribute: Attribute) => {
                let idx = selected.indexOf(attribute.name);
                if(idx < 0)
                    selected.push(attribute.name);
            });

            inputCheckboxes.style.display = "block";
        }

        setState(prevState => ({...prevState, selectedObjectives: selected}));
    }

    /* ATTRIBUTE CHECKBOXES END */

    /* SMOOTHING AND VIOLIN PLOT CHECKBOXES START */

    function renderSmoothingCheckbox() {
        return(
            <div>
                <label className="switch">
                    <input type="checkbox"
                           onChange={event => curveSmoothingHandler(event.target.checked)}/>
                    <span className="slider round"/>
                    <span className="label">Curve Smoothing</span>
                </label>
            </div>
        );
    }

    function curveSmoothingHandler(enable: boolean) {
        setState(prevState => ({...prevState, curveSmoothingEnabled: enable}));
    }

    function renderViolinPlotsCheckbox() {
        return(
            <div>
                <label className="switch">
                    <input type="checkbox"
                           onChange={event => violinPlotsHandler(event.target.checked)}/>
                    <span className="slider round"/>
                    <span className="label">Violin Plots</span>
                </label>
            </div>
        );
    }

    function violinPlotsHandler(enable: boolean) {
        setState(prevState => ({...prevState, violinPlotsEnabled: enable}));
    }

    /* SMOOTHING AND VIOLIN PLOT CHECKBOXES END */

    /* COLOR CODING CHECKBOXES START */

    function renderColorCodingControl() {
        let colorCodingType = state.colorCoding.getType();

        let gradientBrushChecked = colorCodingType === COLOR_CODING.GRADIENT_BRUSH;
        let showGradientAttributeContainer = gradientBrushChecked ? "block" : "none";

        return(
            <div>
                <div>
                    <label className="switch">
                        <input type="checkbox"
                               onChange={event => enableColorCoding(event.target.checked)}/>
                        <span className="slider round"/>
                        <span className="label">Gradient Brush</span>
                    </label>
                </div>
                <div id="color-coding-container"
                     style={{"paddingLeft" : paddingLeftSubsection, "display" : "none"}}>
                    <div style={{"display":"inline-block"}}>
                        <div id="gradient-attribute"
                             style={{"display" : showGradientAttributeContainer}}>
                            <DropDownSelector options={store.data.columns.filter(attribute => state.selectedObjectives.includes(attribute.name) && attribute.obj !== undefined).map(objective => objective.name)}
                                              optionChangeHandler={(attribute: string) => colorCodingAttributeHandler(COLOR_CODING.GRADIENT_BRUSH, attribute)}
                                              fireInitialOnChange={true}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function enableColorCoding(enable: boolean) {
        // Show/hide color-coding control
        let colorCodingControl = document.getElementById("color-coding-container")!;
        colorCodingControl.style.display = enable ? "block" : "none";

        // Enable/disable color-coding of lines
        if(!enable) {
            // Deep copy of class instance
            let colorCoding = Object.create(state.colorCoding);
            let currType = colorCoding.getType();
            colorCoding.disable();
            colorCoding.setType(currType);
            setState(prevState => ({...prevState, colorCoding: colorCoding}));
        } else {
            // Handle color-coding
            colorCodingTypeHandler(COLOR_CODING.GRADIENT_BRUSH, true);
        }
    }

    function colorCodingTypeHandler(type : COLOR_CODING, enable?: boolean) {
        let attributeName : string;
        let attributeDropDown : any;
        switch(type) {
            case COLOR_CODING.GRADIENT_BRUSH:
                attributeDropDown = document.getElementById("gradient-attribute")!;
                attributeName = (attributeDropDown.getElementsByTagName("select")[0] as HTMLSelectElement).value;
                break;
            case COLOR_CODING.CATEGORIES:
                attributeDropDown = document.getElementById("topologies")!;
                attributeName = (attributeDropDown.getElementsByTagName("select")[0] as HTMLSelectElement).value;
                break;
            default:
                attributeName = "";
        }

        colorCodingAttributeHandler(type, attributeName, enable);
    }

    function colorCodingAttributeHandler(type: COLOR_CODING, attributeName: string, enable?: boolean) {
        let attribute = store.data.columns.find((attribute: Attribute) => attribute.name === attributeName);
        if(attribute) {
            // Deep copy of class instance
            let colorCoding = Object.create(state.colorCoding);
            if(enable !== undefined) {
                if(enable)
                    colorCoding.enable();
                else
                    colorCoding.disable();
            }
            // Set color scale
            let scaleConf = {};
            switch(type) {
                case COLOR_CODING.GRADIENT_BRUSH:
                    let domain = [attribute.min, attribute.max];
                    let minimization = attribute.obj === OPTIMIZATION_TYPE.MIN;
                    scaleConf = {
                        "domain" : domain,
                        "minimization" : minimization
                    };
                    colorCoding.setColorScale(type, attribute, scaleConf);
                    break;
                default:
                    break;
            }

            setState(prevState => ({...prevState, colorCoding: colorCoding}));
        }
    }

    /* COLOR CODING CHECKBOXES END */

    /* SELECTION HANDLING START */

    function flaggedIDsChangeHandler(ids: Array<number>) {
        /* Make the selection available to all consumers within this application */
        dispatch({"type": "set-flagged-rows", "payload": ids});

        /* If app is executed with SyMSpace, additionally redirect selection triggered in parallel coordinates to SyMSpace */
        if(filename && port) {
            fetch("http://localhost:" + port + "/symspace/optimizer/plot/version?name=" + filename, {method: "GET"})
                .then(function (response) {
                    if (response.ok) {
                        return response.text();
                    } else
                        throw new Error('Connection Error ' + response.status + '(' + response.statusText + ') at ' + response.url);
                })
                .then(v => {
                    return fetch(
                        "http://localhost:" + port + "/symspace/optimizer/plot/selection?name=" + filename + "&version=" + v,
                        {method: 'PUT',
                            headers: {'Content-Type':'application/json'},
                            body: JSON.stringify(ids)}
                    );
                })
                .then(function (response) {
                    if (response.ok) {
                        return response.text();
                    } else
                        throw new Error('Connection Error ' + response.status + '(' + response.statusText + ') at ' + response.url);
                })
                .catch(error => {
                    throw new Error(error.name + ': ' + error.message);
                });
        }
    }

    /* SELECTION HANDLING END */
};

export default ParallelCoordinatesContainer;