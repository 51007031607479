import * as React from 'react';

import {PROJECT_DESCRIPTION, SYMSPACE} from "../../config/constants";
import {Routes} from "../../config/routes";
import Footer from "../../layouts/Footer";

import "./LandingPage.css"

const LandingPage : React.FunctionComponent<{}> = () =>
        <div style={{width: "100%", height: "100%"}}>
            <div className="page-container">
                <span><h1>{SYMSPACE} </h1><h1 style={{color: "rgb(0, 157, 223)"}}>Choice</h1></span>
                <h2>{PROJECT_DESCRIPTION}</h2>
                <a id="live-demo" className="button" href={Routes.demo}>Live Demo</a>
            </div>
            <Footer/>
        </div>;

export default LandingPage;
