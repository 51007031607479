import * as React from "react";
import {useState} from "react";
import * as d3 from "d3";
import clsx from 'clsx';
import {makeStyles, Table as MuiTable, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import {useStateContext as useStateSessionContext} from "../../contexts/SessionContext";

import { Row } from "../../config/constants";

const useStyles = makeStyles({
    tr: {
        backgroundColor: "var(--color-background-light)",
        "&:hover": {
            background: "linear-gradient(45deg, rgba(239, 108, 0, 0.6) 30%, rgba(255, 142, 83, 0.6) 90%)"
        }
    },
    th: {
        color: "var(--color-solid) !important",
        backgroundColor: "var(--color-background) !important"
    },
    td: {
        color: "var(--color-solid) !important"
    },
    flagged: {
        background: "linear-gradient(45deg, rgba(41, 140, 249, 0.7) 30%, rgba(111, 175, 243, 0.7) 90%)"
    }/*,
    disabled: {
        color: "var(--color-solid-semitransparent)"
    }*/
});

interface Props {
    id: string,
    rows: Array<object>,
    columns: Array<{"ref": string, "name"?: string}>
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const TableView : React.FunctionComponent<Props> = props => {
    const [order, setOrder] = useState<Order>('asc');
    // TODO: remove this hard-coded value
    const [orderBy, setOrderBy] = useState<string>("ID");
    const classes = useStyles();

    const store = useStateSessionContext();

    if(props.rows.length === 0)
        return(<h6 style={{"marginLeft": "10px", "marginTop": "10px"}}>No data to display yet. Please upload a data set first.</h6>);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string,) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return(
        <MuiTable stickyHeader={true}>
            <TableHead>
                <TableRow>
                    {props.columns.map((col: {"ref": string, "name"?: string}, idx: number) => (
                        <TableCell key={idx}
                                   className={classes.th}
                                   align="center"
                                   sortDirection={orderBy === col.ref ? order : false}>
                            <TableSortLabel active={orderBy === col.ref}
                                            direction={orderBy === col.ref ? order : 'asc'}
                                            onClick={(event: React.MouseEvent<unknown>) => {
                                                handleRequestSort(event, col.ref);
                                            }}>
                                {col.name ? col.name : col.ref}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.rows.slice().sort(getComparator(order, orderBy)).map((row: Row) => {
                    return (
                        <TableRow key={row.ID} className={clsx(classes.tr, store.flaggedRows.includes(row.ID) && classes.flagged)} hover={true}>
                            {props.columns.map((col: {"ref": string, "name"?: string}, idx: number) => {
                                let val = (row as any)[col.ref];
                                let text = "";
                                switch(typeof val) {
                                    case "string":
                                        text = val;
                                        break;
                                    case "number":
                                        text = d3.format(".4")(val);
                                        break;
                                    default:
                                        text = "n.a.";
                                }
                                return <TableCell key={idx} className={classes.td} align="center">{text}</TableCell>;
                            })}
                        </TableRow>);
                })}
            </TableBody>
        </MuiTable>
    );
}

export default TableView;